@keyframes waves {
    0% {
        -webkit-transform: scale(0.2, 0.2);
        transform: scale(0.2, 0.2);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    50% {
        opacity: 0.9;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    }
    100% {
        -webkit-transform: scale(0.9, 0.9);
        transform: scale(0.9, 0.9);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}
.jltwp-spotlight-waves::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    background-color: #7370ff99;
    animation: waves 3s linear infinite;
}


// switcher active btn
.jlt-wpspotlight{
    .jlt-switcher-btns{
        background-color: #262626;
        border-color: #262626;
        button{
            color: #fff;
            &.jlt-switcher-active-btn{
                background-color: #0a0a0a;
            }
        }
      
    }
}